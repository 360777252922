// data/mealsData.js

const meals = [
  {
    id: 1,
    lat: 22.28552,
    lng: 114.15768,
    restaurantName: "Gusto - Taste Italian",
    mealName: "Pepperoni Pizza",
    address:
      "61 Mody Rd Shop G3, 3A, 5 and 6, G/F, Mirror Tower, Tsim Sha Tsui",
    description: "Flavorful pepperoni pizza with a crunchy crust.",
    retailPrice: "$139",
    discountedPrice: "$65",
    savePercent: "53%",
    image:
      "https://images.unsplash.com/photo-1605478371310-a9f1e96b4ff4?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fFBpenphfGVufDB8fDB8fHww",
  },
  {
    id: 2,
    lat: 22.30271,
    lng: 114.17722,
    restaurantName: "Ho Hung Kee",
    mealName: "Wonton Soup",
    address: "Shop 1204-1205, 12F, Hysan Place, 500 Hennessy Road",
    description: "Authentic wonton soup with flavorful broth.",
    retailPrice: "$76",
    discountedPrice: "$39",
    savePercent: "48%",
    image:
      "https://images.unsplash.com/photo-1559948271-7d5c98d2e951?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d29udG9ufGVufDB8fDB8fHww",
  },
  {
    id: 3,
    lat: 22.31623,
    lng: 114.18035,
    restaurantName: "Belly Boys",
    mealName: "Beef Burger",
    address:
      "G/F, Jing Long Commercial Building, 52 Tang Lung Street, Causeway Bay",
    description: "Juicy beef burger with fresh toppings.",
    retailPrice: "$88.0",
    discountedPrice: "$51",
    savePercent: "42%",
    image:
      "https://plus.unsplash.com/premium_photo-1664472724753-0a4700e4137b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fEJ1cmdlcnxlbnwwfHwwfHx8MA%3D%3D",
  },
  {
    id: 4,
    lat: 22.3001585,
    lng: 114.152284,
    restaurantName: "Kane Tsuru Ramen",
    mealName: "Shoyo Ramen",
    address: "G/F, 3 Min Street Jordan",
    description: "Umami-rich Shoyo Ramen with tender noodles.",
    retailPrice: "$72.0",
    discountedPrice: "$35",
    savePercent: "51%",
    image:
      "https://images.unsplash.com/photo-1569718212165-3a8278d5f624?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8UmFtZW58ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 5,
    lat: 22.275873,
    lng: 114.1682781,
    restaurantName: "22 Ships",
    mealName: "Classic Paella",
    address: "No.22 Ship Street, Wan Chai",
    description: "Rich, aromatic paella with seafood and rice.",
    retailPrice: "$108",
    discountedPrice: "$57",
    savePercent: "47%",
    image:
      "https://images.unsplash.com/photo-1604543519952-12b7038886c0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fFBhZWxsYXxlbnwwfHwwfHx8MA%3D%3D",
  },
];

export default meals;
