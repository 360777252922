import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import meals from "../assets/data/mealsData";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 22.268132,
  lng: 114.17252,
};

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.secondary,
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  border: "none",
  boxShadow: "none",
  alignItems: "center",
  height: "100%",
  fontFamily: theme.typography.fontFamily,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  objectFit: "cover",
  objectPosition: "center",
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: "35vw",
    height: "35vw",
  },
  [theme.breakpoints.up("md")]: {
    width: `min(35vw,15vh)`,
    height: `min(35vw,15vh)`,
  },
  [theme.breakpoints.up("lg")]: {
    width: `min(35vw,23vh)`,
    height: `min(35vw,23vh)`,
  },
  width: "200px",
  height: "200px",
}));

const MapContainer = styled(Box)(({ theme }) => ({
  flex: 3,
  height: "500px",
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(2),
  width: "min(35%, 40vw)",
  position: "relative",
  zIndex: 1,
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "left",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: theme.spacing(1),
    top: -100,
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
    top: -150,
  },
  [theme.breakpoints.up("lg")]: {
    width: "min(35%, 40vw)",
    top: -150,
  },
  width: "70%",
  top: -150,
}));

const BorderContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
  maxWidth: "1100px",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  paddingTop: "2em",
}));

const SaveBadge = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  fontSize: "0.875rem",
  padding: theme.spacing(0.5, 1),
  fontWeight: "bold",
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  border: "none",
  textTransform: "uppercase",
  marginBottom: "0.5em",
  display: "inline-block",
  width: "80px",
}));

const DiscountedPrice = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: "bold",
  fontSize: "1.3rem",
  marginBottom: theme.spacing(1),
}));

const Divider = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  margin: theme.spacing(1, 0),
}));

const MealsWithMap = () => {
  const [selectedLocation, setSelectedLocation] = useState(meals[0]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const h6FontSize = theme.typography.h6.fontSize;
  const body2FontSize = theme.typography.body2.fontSize;

  const createMarkerIcon = (color) => {
    return {
      path: "M12 2C8.13 2 5 5.13 5 9c0 3.25 2.13 6.4 4.53 9.16.76.89 1.59 1.72 2.47 2.52.87-.8 1.71-1.64 2.47-2.52C16.87 15.4 19 12.25 19 9c0-3.87-3.13-7-7-7zm0 10.5c-1.93 0-3.5-1.57-3.5-3.5S10.07 5.5 12 5.5 15.5 7.07 15.5 9 13.93 12.5 12 12.5z",
      fillColor: color,
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 2,
    };
  };

  return (
    <StyledContainer align="center" maxWidth={false}>
      <Title>Explore Meals in Hong Kong</Title>
      <BorderContainer>
        <MapContainer>
          <LoadScript googleMapsApiKey="AIzaSyD1ZRmudvGS24bBy8w-kM9lqVBmGug2bSM">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={12}
              options={{
                styles: require("../config/CustomMapStyle"),
                streetViewControl: false,
                mapTypeControl: false,
              }}
            >
              {meals.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => setSelectedLocation(location)}
                  icon={
                    location === selectedLocation
                      ? createMarkerIcon(theme.palette.primary.main)
                      : createMarkerIcon(theme.palette.secondary.main)
                  }
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </MapContainer>
      </BorderContainer>
      <InfoContainer>
        {selectedLocation ? (
          <StyledCard>
            <StyledCardMedia
              image={selectedLocation.image}
              title={selectedLocation.restaurantName}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: isSmallScreen ? "150px" : "200px",
                width: "50%",
              }}
            >
              <div
                style={{
                  fontSize: isSmallScreen
                    ? `min(1rem, ${h6FontSize})`
                    : `min(1.5rem, ${h6FontSize})`,
                  fontWeight: "bold",
                  marginBottom: "0.5em",
                  marginTop: "0.5em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedLocation.mealName}
              </div>
              <div
                style={{
                  fontSize: isSmallScreen
                    ? `min(0.875rem, ${body2FontSize})`
                    : `min(1rem, ${body2FontSize})`,
                  color: "grey",
                  marginBottom: "0.5em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedLocation.restaurantName}
              </div>
              {!isSmallScreen && (
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedLocation.description}
                </Typography>
              )}

              {!isSmallScreen && <Divider />}
              <SaveBadge>Save {selectedLocation.savePercent}</SaveBadge>
              <div
                style={{
                  fontSize: isSmallScreen
                    ? `min(0.875rem, ${body2FontSize})`
                    : `min(1rem, ${body2FontSize})`,
                  color: "grey",
                  textDecoration: "line-through",
                }}
              >
                Retail Price: {selectedLocation.retailPrice}
              </div>
              <DiscountedPrice
                style={{
                  fontSize: isSmallScreen
                    ? `min(1rem, ${h6FontSize})`
                    : `min(1.5rem, ${h6FontSize})`,
                }}
              >
                Your Price: {selectedLocation.discountedPrice}
              </DiscountedPrice>
            </Box>
          </StyledCard>
        ) : (
          <div
            style={{
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            Click on a marker to see restaurant details
          </div>
        )}
      </InfoContainer>
    </StyledContainer>
  );
};

export default MealsWithMap;
