import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import LogoSlogan from "./components/LogoSlogan";
import MealsDisplay from "./components/MealsDisplay";
import MealsWithMap from "./components/MealsWithMap";
import MealPlans from "./components/MealPlans";
import SellingPoints from "./components/SellingPoints";
import AppScreenshots from "./components/AppScreenshots";
import Footer from "./components/Footer";
import theme from "./theme";
import { GlobalStyles } from "@mui/system";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ body: { margin: 0, padding: 0 } }} />
      <Header />
      <LogoSlogan />
      <MealsDisplay />
      <MealsWithMap />
      <MealPlans />
      <SellingPoints />
      <AppScreenshots />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
