// src/components/Footer.js
import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: "2em 0",
  marginTop: "2em",
  textAlign: "center",
}));

function Footer() {
  return (
    <StyledContainer maxWidth="false">
      <Typography variant="body2" color="textSecondary">
        © 2024 GrubGo. All rights reserved.
      </Typography>
    </StyledContainer>
  );
}

export default Footer;
