const mealPlans = [
  {
    title: "Basic Plan",
    image:
      "https://images.unsplash.com/photo-1493770348161-369560ae357d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: "$400/month",
    features: ["400 Meal Credits", "Priority Pickup", "24/7 Support"],
  },
  {
    title: "Standard Plan",
    image:
      "https://images.unsplash.com/photo-1493770348161-369560ae357d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: "$750/month",
    features: [
      "750 Meal Credits + 75 Free Credits",
      "Priority Pickup",
      "24/7 Support",
    ],
  },
  {
    title: "Premium Plan",
    image:
      "https://images.unsplash.com/photo-1493770348161-369560ae357d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: "$1000/month",
    features: [
      "1000 Meal Credits + 125 Free Credits",
      "Priority Pickup",
      "24/7 Support",
    ],
  },
];

export default mealPlans;
