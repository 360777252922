// src/components/AppScreenshots.js
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import screenshot1 from "../assets/images/screenshots.jpg";
import appstoreBadge from "../assets/images/appstore-badge.png";
import googleplayBadge from "../assets/images/googleplay-badge.png";

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "4em",
  paddingBottom: "4em",
}));

const ScreenshotImage = styled("img")(({ theme }) => ({
  width: `min(75%,70vh)`,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
}));

const AppScreenshots = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledContainer alignItems="center">
      <Grid container textAlign="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <ScreenshotImage src={screenshot1} alt="App Screenshot" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign={isSmallScreen ? "center" : "left"}>
            <Title>Download Our App</Title>
            <Description gutterBottom>
              Our app gives you access to your favorite restaurants. Order ahead
              and pick up your meal without the wait.
            </Description>
            <Box mt={2}>
              <img
                src={appstoreBadge}
                alt="Download on the App Store"
                style={{ marginRight: "1em", height: "50px" }}
              />
              <img
                src={googleplayBadge}
                alt="Get it on Google Play"
                style={{ height: "50px" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AppScreenshots;
