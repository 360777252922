// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8C00",
      light: "#fcf6f2",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: "Commissioner, Arial, sans-serif",
  },
  fontSize: {
    primary: "1.15rem",
    secondary: "1.5rem",
  },
});

export default theme;
