import { useState, useEffect } from "react";

const useTypewriter = (text, speed = 50, delay = 0) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayText((prevText) => prevText + text.charAt(i));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    const delayTimeout = setTimeout(() => {
      return () => {
        clearInterval(typingInterval);
        clearTimeout(delayTimeout);
      };
    }, delay);

    return () => {
      clearInterval(typingInterval);
      clearTimeout(delayTimeout);
    };
  }, [text, speed, delay]);

  return displayText;
};

export default useTypewriter;
