import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import mealPlans from "../assets/data/mealPlanData";
import CardMedia from "@mui/material/CardMedia";

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "2em",
  paddingBottom: "2em",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const FeatureList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  margin: 0,
  marginBottom: theme.spacing(2),
}));

const FeatureItem = styled("li")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const FeatureText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 180,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const ChooseButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  textTransform: "none",
  fontWeight: "bold",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "350px",
  margin: "0 auto",
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  transition: "transform 0.3s ease-in-out",
  height: "450px", // Set a fixed height for the card
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

function MealPlans() {
  return (
    <StyledContainer align="center">
      <Title>Choose the Best Plan for You</Title>

      <Grid container spacing={4}>
        {mealPlans.map((plan, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <StyledCard>
              <StyledCardMedia image={plan.image} />
              <CardContent align="left">
                <PlanTitle>{plan.title}</PlanTitle>
                <Price>{plan.price}</Price>
                <FeatureList>
                  {plan.features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <CheckCircleIcon color="primary" />
                      <FeatureText>{feature}</FeatureText>
                    </FeatureItem>
                  ))}
                </FeatureList>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
}

export default MealPlans;
