import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import LocalDiningIcon from "@mui/icons-material/LocalDining";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  width: "100%",
  boxShadow: "none",
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "0px 6px 4px -2px rgba(0, 0, 0, 0.1)",
  borderTop: "none",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.fontSize.primary,
  fontWeight: 550,
}));

const PartnerLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  fontSize: theme.fontSize.primary,
  fontWeight: 550,
  fontFamily: theme.typography.fontFamily,
}));

function Header() {
  return (
    <CustomAppBar position="static">
      <CustomToolbar>
        <LogoContainer>
          <LocalDiningIcon
            fontSize="large"
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
          <Title>GRUBGO</Title>
        </LogoContainer>
        <PartnerLink href="https://main.d1dls3xwu0jktr.amplifyapp.com">
          Join as Partner
        </PartnerLink>
      </CustomToolbar>
    </CustomAppBar>
  );
}

export default Header;
