import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TimeIcon from "@mui/icons-material/AccessTime";
import RestaurantIcon from "@mui/icons-material/Restaurant";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.secondary,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "2em",
  paddingBottom: "2em",
  backgroundColor: theme.palette.primary.light,
}));

const PointContainer = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
}));

const PointIcon = styled("div")(({ theme }) => ({
  fontSize: "4rem",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const PointTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const PointDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body1.fontSize,
}));

function SellingPoints() {
  const points = [
    {
      icon: <WalletIcon fontSize="inherit" />,
      title: "Affordable Meals",
      description: "Relish flavorful dishes at pocket-friendly prices.",
    },
    {
      icon: <TimeIcon fontSize="inherit" />,
      title: "Time-Saving Convenience",
      description: "Quick and easy meal pickups at your convenience.",
    },
    {
      icon: <RestaurantIcon fontSize="inherit" />,
      title: "Top-Quality Choices",
      description: "Savor dishes from the best restaurants in town.",
    },
  ];

  return (
    <StyledContainer align="center" maxWidth={false}>
      <Title>Why Choose Grubgo?</Title>
      <Grid container spacing={4} style={{ maxWidth: "1100px" }}>
        {points.map((point, index) => (
          <PointContainer item key={index} xs={12} sm={6} md={4}>
            <PointIcon>{point.icon}</PointIcon>
            <PointTitle>{point.title}</PointTitle>
            <PointDescription>{point.description}</PointDescription>
          </PointContainer>
        ))}
      </Grid>
    </StyledContainer>
  );
}

export default SellingPoints;
